import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Hero from './components/Hero/Hero';
import Services from './components/Services/Services';
import Testimonials from './components/Testimonials/Testimonials';
import Footer from './components/Footer/Footer';
import Navbar from './components/Navbar/Navbar';
import EmailVerification from './components/SubmitOrder/EmailVerification';
import OrderForm from './components/SubmitOrder/OrderForm';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Privacy from './components/Privacy/Privacy';
import Refund from './components/Refund/Refund';
import FAQ from './components/FAQ/FAQ';
import OrderStatus from './components/SubmitOrder/OrderStatus';
import ReactGA from 'react-ga';

import { LanguageProvider, LanguageContext } from './LanguageContext';
import './App.css';

const ContentWrapper = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  return (
    <div className="content-wrapper">
      <Navbar />
      <Routes>
        <Route path="/orderStatus" element={<OrderStatus />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/refund" element={<Refund />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/submitorder" element={<EmailVerification />} />
        <Route path="/orderform" element={<OrderForm />} />
        <Route path="/" element={
          <>
            <Hero />
            <Services />
            <Testimonials />
          </>
        } />
      </Routes>
    </div>
  );
};

const MainApp = () => {
  const { currentLanguage } = useContext(LanguageContext);

  useEffect(() => {
    if (currentLanguage === 'FA') {
      document.body.classList.add('farsi');
    } else {
      document.body.classList.remove('farsi');
    }
  }, [currentLanguage]);

  return (

      <div id="app">
        <ContentWrapper />
        <Footer />
      </div>

  );
};

function App() {
  return (
    <Router>
      <LanguageProvider>
        <MainApp />
      </LanguageProvider>
    </Router>
  );
}

export default App;
