import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Contact.css';

const contactContent = {
  EN: {
    title: "Contact Us",
    text: "For General Inquiries and Support:",
    emailLabel: "Email:",
    email: "info@zendehyad.com",
    instaLabel: "Instagram:",
    instagram: "@zendehyaad",
    telegramLabel: "Telegram:",
    telegram: "@zendeh_yaad",
    footer: "We aim to respond to all emails within 24 hours."
  },
  FA: {
    title: "تماس با ما",
    text: "برای سوالات عمومی و پشتیبانی:",
    emailLabel: "ایمیل:",
    email: "info@zendehyad.com",
    instaLabel: "اینستاگرام:",
    instagram: "zendehyaad",
    telegramLabel: "تلگرام:",
    telegram: "zendeh_yaad",
    footer: "ما تلاش می‌کنیم تا به تمامی پیام‌های شما در طی 24 ساعت پاسخ دهیم."
  }
};

const Contact = () => {
    const { currentLanguage } = useContext(LanguageContext);
    const currentContact = contactContent[currentLanguage];
  
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, []);
    
    return (
      <div className={`contact-us ${currentLanguage === 'FA' ? 'rtl' : ''}`}>
        <div className="card">
          <h1>{currentContact.title}</h1>
          <p>{currentContact.text}</p>
          
          <p><strong>{currentContact.instaLabel}</strong> <FontAwesomeIcon icon={faInstagram} className="social-icons" /><a href='https://instagram.com/zendehyaad'>{currentContact.instagram}</a></p>
          
          <p><strong>{currentContact.telegramLabel}</strong> <FontAwesomeIcon icon={faTelegram} className="social-icons" /><a href='https://t.me/zendeh_yaad'>{currentContact.telegram}</a></p>
          
          <p><strong>{currentContact.emailLabel}</strong> {currentContact.email}</p>
          <p>{currentContact.footer}</p>
        </div>
      </div>
    );
  };

export default Contact;
