import React, { useEffect, useState, useContext } from 'react';
import PasswordEntry from './PasswordEntry'; // Assuming you have this component in the same directory
import { LanguageContext } from '../../LanguageContext'; // Adjust the path accordingly
import { useNavigate } from 'react-router-dom';
import './EmailVerification.css';

function EmailVerification() {
    const { currentLanguage } = useContext(LanguageContext);

    const translations = {
        EN: {
            emailPlaceholder: "Enter your email",
            nextButton: "Next",
            passwordSentAlert: "Password has been sent to your email.",
            verificationError: "Error verifying email.",
            networkError: "Network or server error.",
            price: "Price: $10",
            title: "Submit order",
            wrongEmail: "Please enter a valid email address.",
            loginTitle: "Login or Resgister",
            namePlaceholder: "Enter your name",
            priceCardDescription: `Grave cleaning service and lighting a candle on your departed loved one's tombstone, along with photo and video delivery.\nCoverage for Behesht Zahra and Behesht Sakineh cemeteries.`,
        },
        FA: {
            emailPlaceholder: "ایمیل خود را وارد کنید",
            nextButton: "بعدی",
            passwordSentAlert: "رمزعبور به ایمیل شما ارسال شد.",
            verificationError: "خطا در تأیید ایمیل.",
            networkError: "خطای شبکه یا سرور.",
            price: "هزینه: 10$",
            title: "ثبت درخواست",
            wrongEmail: "لطفا ایمیل معتبر وارد نمایید.",
            loginTitle: "ورود یا ثبت نام",
            namePlaceholder: "نام شما",
            priceCardDescription: "سرویس غبارروبی و افروختن شمع بر مزار رفتگان به همراه ارسال فیلم و عکس. \nپوشش دهی بهشت زهرا و بهشت سکینه.",
        }
    };

    const [email, setEmail] = useState('');
    const [showPasswordEntry, setShowPasswordEntry] = useState(false);
    const [isEmailExisting, setIsEmailExisting] = useState(false);
    const [name, setName] = useState('');
    const [showNameField, setShowNameField] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const cachedEmail = localStorage.getItem('email');
        const cachedPassAuthResult = localStorage.getItem('passAuthResult');

        if (cachedEmail && cachedPassAuthResult && cachedPassAuthResult !== 'false') {
            navigate('/orderform');
        }
    }, [navigate]);

    const currentTexts = translations[currentLanguage];

    const handleNextClick = async () => {
        const emailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
        if (!emailValid) {
            setAlertMessage(currentTexts.wrongEmail);
            return;
        } else {
            setAlertMessage('');
        }
        setIsLoading(true);
        // API call to your server to check email and possibly send a password.
        try {
            const response = await fetch(`https://zyapi.vercel.app/check-user/${email}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            });
            const data = await response.json();
            console.log(data.status)
            if (data.status === 'exist') {
                setIsEmailExisting(true);
                setShowPasswordEntry(true);
            } else if (data.status === 'not exist') {
                setShowNameField(true);
            } else if (data.status === 'password_sent') {
                setAlertMessage(currentTexts.passwordSentAlert);
                setShowPasswordEntry(true);
            }
            else {
                setAlertMessage(currentTexts.verificationError);
            }
        } catch (error) {
            setAlertMessage(currentTexts.networkError);
        }
        setIsLoading(false);
    };

    const handleRegister = async () => {
        const emailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
        if (!emailValid) {
            setAlertMessage(currentTexts.wrongEmail);
            return;
        } else {
            setAlertMessage('');
        }
        setIsLoading(true);
        try {

            const response = await fetch('https://zyapi.vercel.app/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, name })
            });
            const data = await response.json();
            console.log(data)
            if (data.status === 'User registered and email sent!') {
                setAlertMessage(currentTexts.passwordSentAlert);
                setShowPasswordEntry(true);
                setShowNameField(false);
            } else {
                setAlertMessage(currentTexts.verificationError);
            }
        } catch (error) {
            setAlertMessage(currentTexts.networkError);
        }
        setIsLoading(false);
    };


    const handleProceedToOrderForm = () => {
        navigate('/orderform');

    };

    return (
        <div className={`main-container ${currentLanguage === 'FA' ? 'rtl' : ''}`}>
            <h1 className="order-title">{currentTexts.title}</h1> {/* Adding Title */}

            <div className="price-card-email">

                <p className='service-p'>
                    {currentTexts.priceCardDescription.split('\n').map((line, index) => {
                        return (
                            <span key={index} >
                                {line}
                                <br />
                            </span>
                        );
                    })}
                </p>
                <span className='service-span'>{currentTexts.price}</span>
                <div class='payment-logo-containere'>
            <img class='payment-logop' src='/images/perfectmoneysq.png' alt="Perfect Money Logo" />
            <img class='payment-logo' src='/images/mastercard.png' alt="Master Card Logo" />
            <img class='payment-logo' src='/images/visa.png' alt="Visa Logo" />
            
            
            
            </div>
            </div>

            <h2 className='login-title'>{currentTexts.loginTitle}</h2>
            {isLoading && (
          <div className="loader"></div>  // Add this line for loading animation
        )}
            <div className="email-verification-container">
                {alertMessage && (
                    <div className={`alert ${alertMessage === currentTexts.passwordSentAlert ? 'alert-success' : 'alert-error'}`}>
                        {alertMessage}
                    </div>
                )}
                {!showPasswordEntry ? (
                    <form onSubmit={(e) => { e.preventDefault(); showNameField ? handleRegister() : handleNextClick(); }}>
                        <div className="email-input-section">
                            <input
                                className="email-input"
                                type="email"
                                placeholder={currentTexts.emailPlaceholder}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {showNameField && (
                                <input
                                    className="name-input"
                                    type="text"
                                    placeholder={currentTexts.namePlaceholder}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            )}
                            <button className="next-button" type="submit">
                                {currentTexts.nextButton}
                            </button>
                        </div>
                    </form>

                ) : (
                    <>

                        <PasswordEntry name={name} email={email} isNewUser={!isEmailExisting} proceedToOrderForm={handleProceedToOrderForm} />
                    </>

                )}
            </div>
        </div>
    );
}



export default EmailVerification;
