import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../LanguageContext';
import './Privacy.css';

const content = {
    EN: {
      title: "Privacy Policy for Zendeh Yad",
      sections: [
        {
          subtitle: "Last updated: 2023 Aug",
          text: ""
        },
        {
          subtitle: "Introduction",
          text: "Welcome to the Zendeh Yad website. This Privacy Policy governs the manner in which Zendeh Yad collects, uses, maintains, and discloses information collected from users."
        },
        {
          subtitle: "Information We Collect",
          text: "Personal Information: We collect your name, email address, and any other information you provide when placing an order.\nTechnical Information: We collect information like your IP address, device type, and cookies to improve your user experience."
        },
        {
          subtitle: "How We Use the Information",
          text: "To process your orders for grave cleaning and candle lighting services.\nTo send updates and notifications related to your orders."
        },
        {
          subtitle: "Data Storage and Security",
          text: "We implement a variety of security measures to maintain the safety of your personal information."
        },
        {
          subtitle: "Third-Party Disclosure",
          text: "We do not sell, trade, or rent your personal information to third parties."
        },
        {
          subtitle: "User Choices",
          text: "You have the right to access, correct, or delete your personal information stored with us."
        },
        {
          subtitle: "Changes to this Policy",
          text: "We may update this Privacy Policy at any time, and the updated version will be posted on this website."
        },
        {
          subtitle: "Contact Us",
          text: "If you have any questions about this Privacy Policy, please contact us at info@zendehyad.com."
        }
      ]
    },
    FA: {
      title: "حریم خصوصی زنده یاد",
      sections: [
        {
          subtitle: "آخرین به‌روزرسانی: آگوست 2023",
          text: ""
        },
        {
          subtitle: "مقدمه",
          text: "به وبسایت زنده یاد خوش آمدید. این سیاست حریم خصوصی، روش‌های جمع‌آوری، استفاده، نگهداری و افشای اطلاعات کاربران توسط زنده یاد را توضیح می‌دهد."
        },
        {
          subtitle: "اطلاعاتی که جمع‌آوری می‌شود",
          text: "اطلاعات شخصی: نام، آدرس ایمیل و هر اطلاعاتی که هنگام ثبت سفارش ارائه می‌دهید، جمع‌آوری می‌شود.\nاطلاعات فنی: آدرس IP، نوع دستگاه و کوکی‌ها برای بهبود تجربه کاربری شما جمع‌آوری می‌شود."
        },
        {
          subtitle: "چگونگی استفاده از اطلاعات",
          text: "برای پردازش سفارشات شما در خصوص خدمات غبارروبی و شمع روشن کردن.\nبرای ارسال به‌روزرسانی‌ها و اطلاعیه‌های مرتبط با سفارشات شما."
        },
        {
          subtitle: "ذخیره‌سازی و امنیت داده‌ها",
          text: "ما اقدامات امنیتی متنوعی را برای حفظ امنیت اطلاعات شخصی شما اجرا می‌کنیم."
        },
        {
          subtitle: "افشای اطلاعات به طرف‌های سوم",
          text: "اطلاعات شخصی شما به طرف‌های سوم فروخته، معامله یا اجاره نمی‌شود."
        },
        {
          subtitle: "انتخابات کاربر",
          text: "شما حق دارید اطلاعات شخصی‌تان را که با ما ذخیره شده است، دسترسی پیدا کنید، اصلاح یا حذف کنید."
        },
        {
          subtitle: "تغییرات در این سیاست",
          text: "ما ممکن است هر زمان این سیاست حریم خصوصی را به‌روز کنیم، و نسخه به‌روز شده در این وبسایت قرار خواهد گرفت."
        },
        {
          subtitle: "تماس با ما",
          text: "اگر سوالی در مورد این سیاست حریم خصوصی دارید، لطفاً با ما تماس بگیرید در info@zendehyad.com."
        }
      ]
    }
  };
  
function Privacy() {
  const { currentLanguage } = useContext(LanguageContext);
  const currentContent = content[currentLanguage];
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  return (
    <div className={`privacy-policy ${currentLanguage === 'FA' ? 'rtl' : ''}`}>
      <div className="card">
        <h1>{currentContent.title}</h1>
        {currentContent.sections.map((section, index) => (
          <div key={index}>
            <h2>{section.subtitle}</h2>
            <p>{section.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Privacy;
