import React, { useState, useContext, useEffect } from 'react';
import { LanguageContext } from '../../LanguageContext';
import { useNavigate } from 'react-router-dom';
import './OrderForm.css';
import Invoice from './Invoice';

function OrderForm() {
    const { currentLanguage } = useContext(LanguageContext);
    const [showInvoice, setShowInvoice] = useState(false);
    const [price, setPrice] = useState(10);
    const [subOrder, setSubOrder] = useState();
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null);


    useEffect(() => {
        // Check for required data in localStorage
        const email = localStorage.getItem('email');
        const passAuthResult = localStorage.getItem('passAuthResult');

        // If any data is missing, redirect to EmailVerification
        if (!email || passAuthResult !== 'true') {
            navigate('/submitorder'); // Adjust the route if it's different
        }
    }, [navigate]);
    // State

    const initialFormData = {
        cemetery: '',
        firstName: '',
        lastName: '',
        detailsSelection: '',
        piece: '',
        row: '',
        number: '',
        approxYear: '',
        fathersName: '',
        approxPiece: '',
        contactMethod: '',
        contactNumber: '',
        orders: [],  // Remember to add this from the previous solution
        playSound: false,           // Checkbox for sound preference
        soundOption: '',            // Dropdown for sound option
        soundDescription: ''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [addedData, setAddedData] = useState([]);
    let [canSubmit, setCanSubmit] = useState(false);
    const [finalData, setFinalData] = useState([]);


    // Example translations (you may expand as per requirements)
    const translations = {
        EN: {
            cemeteryPlaceholder: 'Select Cemetery',
            beheshtZahra: 'Behesht-e-Zahra',
            beheshtSakine: 'Behesht-e-Sakine',
            firstName: 'First Name',
            lastName: 'Last Name',
            knowDetails: 'I know exact location details',
            dontKnowDetails: 'I don\'t know exact location details',
            add: '+ Add another',
            contactWay: 'I want to add contact way',
            telegram: 'Telegram',
            whatsapp: 'Whatsapp',
            submitOrder: 'Next',
            playSound: 'I want to play sound',
            soundOptions: {
                quran: "Play Quran",
                dua: "Play Dua",
                customSound: "Custom Sound"
            },
            soundOptionsPlaceholder: "- select option -",
            soundDescriptionLabels: {
                quran: "Description for Quran",
                dua: "Description for Dua",
                customSound: "Description for Custom Sound"
            },
            piece: "Piece",
            row: "Row",
            number: "Number",
            approximateYear: "Approximate Year",
            fathersName: "Father's Name",
            approximatePiece: "Approximate Piece",
            contactNumber: "Contact Number",
            remove: "Remove",
            currentPrice: "Current Price: ",
            titleone: 'ZendehYad information',
            titletwo: 'Additional contact method (optional)',
            titlepage: 'Submit Request Form',
            titleinvoice: 'Review and Payment',
            eCemetery: "Please select a cemetery.",
            eName: "Both first name and last name are required.",
            eSelection: "Please fill in all the details fields.",
            eExact: "Please fill in all the exact details fields.",
            eApprox: "Please fill in at least one of the approximate details fields.",
            eSound: "Please select a sound option and describe that.",
            eContact: "Please enter a contact number for the chosen contact method.",
            eSubmit: "Order submitted successfully!",
            sSubmit: "Error submitting order. Please try again later.",
            approxinfo: "* Fill in at least one of the approximate details fields.",
            contactMethodInfo: "* Images of your request will be sent via messaging app in addition to email."
        },
        FA: {
            cemeteryPlaceholder: 'انتخاب آرامگاه',
            beheshtZahra: 'بهشت زهرا',
            beheshtSakine: 'بهشت سکینه',
            firstName: 'نامِ زنده‌یاد',
            lastName: 'نام خانوادگیِ زنده‌یاد',
            knowDetails: 'جزئیات دقیق قطعه و ردیف را می‌دانم.',
            dontKnowDetails: 'جزئیات دقیق را نمی‌دانم.',
            add: '+ افزودن دیگری',
            contactWay: 'افزودن راه ارسال فیلم یا تصاویر )دلخواه)',
            telegram: 'تلگرام',
            whatsapp: 'واتساپ',
            submitOrder: 'مرحله بعد',
            playSound: 'پخش صوت بر مزار',
            soundOptions: {
                quran: "پخش قرآن",
                dua: "پخش ادعیه",
                customSound: "صوت دلخواه"
            },
            soundOptionsPlaceholder: "- انتخاب گزینه -",
            soundDescriptionLabels: {
                quran: "توضیحات برای قرآن (مثال: سوره فاتحه و صافات)",
                dua: "توضیحات برای ادعیه (مثال: دعای بیستم صحیفه سجادیه)",
                customSound: "توضیحات برای صوت دلخواه (مثال: ترانه خداحافظ خواجه امیری)"
            },
            piece: "قطعه",
            row: "ردیف",
            number: "شماره",
            approximateYear: "سال تقریبی (مثال: حدود 1370)",
            fathersName: "نام پدر (مثال: محمد یا محمدرضا)",
            approximatePiece: "قطعه تقریبی (مثال: قطعه 112 یا 114)",
            contactNumber: "شماره تماس",
            remove: "حذف",
            currentPrice: "قیمت کنونی: ",
            titleone: 'مشخصات زنده‌یاد',
            titletwo: 'افزودن راه ارتباطی دیگر (اختیاری)',
            titlepage: 'فرم ثبت درخواست',
            titleinvoice: 'بررسی مشخصات و پرداخت',
            eCemetery: "لطفا آرامگاه را انتخاب کنید.",
            eName: "لطفا نام و نام‌خانوادگی را وارد کنید.",
            eSelection: "لطفا جزئیات را انتخاب کنید.",
            eExact: "لطفا قطعه، ردیف و شماره را وارد کنید.",
            eApprox: "لطفا حداقل یکی از جزئیات تقریبی را وارد نمایید.",
            eSound: "لطفا نوع صوتی که مایل به پخش هستید انتخاب کنید و توضیحی در مورد آن بنویسید.",
            eContact: "لطفا برای راه ارتباطی انتخاب شده شماره معتبر وارد کنید.",
            eSubmit: "درخواست شما با موفقیت ثبت شد!",
            sSubmit: "خطایی در هنگام ثبت درخواست رخ داده است، لطفا مجدد تلاش کنید.",
            approxinfo: "* حداقل یکی از سه قسمت را وارد کنید.",
            contactMethodInfo: "* تصاویر درخواست شما علاوه بر ایمیل از طریق پیام‌رسان‌ ارسال می‌شود."

        }
    };

    const currentTranslations = translations[currentLanguage];

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    // Logic to handle 'Add' and save data temporarily
    const handleAddData = () => {
        // Validation

        if (!formData.cemetery) {
            setErrorMessage(currentTranslations.eCemetery);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCanSubmit(false);
            return false;
        }
        else if (!formData.firstName || !formData.lastName) {
            setErrorMessage(currentTranslations.eName);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCanSubmit(false);
            return false;
        }
        else if (!formData.detailsSelection) {
            setErrorMessage(currentTranslations.eSelection);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCanSubmit(false);
            return false;
        }
        else if (formData.detailsSelection === 'exact' && (!formData.piece || !formData.row || !formData.number)) {
            setErrorMessage(currentTranslations.eExact);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCanSubmit(false);
            return false;
        }

        else if (formData.detailsSelection === 'approximate' && (!formData.approxYear && !formData.fathersName && !formData.approxPiece)) {
            setErrorMessage(currentTranslations.eApprox);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCanSubmit(false);
            return false;
        }

        else if (formData.playSound === true && (!formData.soundOption || !formData.soundDescription)) {
            setErrorMessage(currentTranslations.eSound);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setCanSubmit(false);
            return false;
        }
        setCanSubmit(true);



        const singleOrder = {
            name: formData.firstName,
            lastname: formData.lastName,
            cemetery: formData.cemetery,
            piece: formData.piece,
            row: formData.row,
            number: formData.number,
            approxPiece: formData.approxPiece,
            approxYear: formData.approxYear,
            fatherName: formData.fathersName,
            soundOption: formData.soundOption,
            soundDescription: formData.soundDescription,
            detailsSelection: formData.detailsSelection
        };


        const updatedOrders = [...(formData.orders || []), singleOrder];
        setFormData(prevData => ({
            ...prevData,
            orders: updatedOrders
        }));

        // Update the addedData to keep track of all orders
        setAddedData(prevAddedData => [...prevAddedData, singleOrder]);

        // Update the formData state with the updated orders.
        setFormData(prevData => ({
            ...prevData,
            orders: updatedOrders
        }));


        // Clear the current formData for new inputs
        setFormData(prevData => ({
            cemetery: '',
            firstName: '',
            lastName: '',
            detailsSelection: '',
            piece: '',
            row: '',
            number: '',
            approxYear: '',
            fathersName: '',
            approxPiece: '',
            contactMethod: prevData.contactMethod,  // Keep the previous value
            contactNumber: prevData.contactNumber,  // Keep the previous value
            orders: [],  // Remember to add this from the previous solution
            playSound: false,           // Checkbox for sound preference
            soundOption: '',            // Dropdown for sound option
            soundDescription: ''
        }));

        if (addedData.length > 0) {
            setPrice(prevPrice => prevPrice + 10);
        }
        setErrorMessage('');
        return true;
    };





    const handleRemoveData = (index) => {
        const updatedData = [...addedData];
        updatedData.splice(index, 1);
        setAddedData(updatedData);

        if (addedData.length > 1) {
            setPrice(prevPrice => prevPrice - 10);
        }
    };

    const handleResetContactMethod = () => {
        // Assuming formData is managed by a setState method, set the fields to default
        setFormData({
            ...formData,
            contactMethod: '',
            contactNumber: ''
        });
    };

    const handleBackToForm = () => {
        setShowInvoice(false);
    };


    const handleShowInvoice = () => {
        if (formData.contactMethod && !formData.contactNumber) {
            setErrorMessage(currentTranslations.eContact);
            setCanSubmit(false);
            return;
        }


        if (addedData.length === 0) {
            const isValid = handleAddData();

            if (isValid) {
                setShowInvoice(true);
                setErrorMessage('');
            }
        } else if (addedData.length > 0) {
            if (formData.cemetery || formData.firstName || formData.lastName || formData.detailsSelection || formData.playSound) {
                const isValid = handleAddData();
                if (isValid) {
                    setShowInvoice(true);
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      });
                    setErrorMessage('');

                }
            } else {
                setShowInvoice(true);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                  });
                setErrorMessage('');
            }
        }


    };


    useEffect(() => {
        console.log("addedData:", addedData);
    }, [addedData]);

    const email = localStorage.getItem('email');
    const name = localStorage.getItem('name');


    
    const handleSubmitOrder = async (billingInfo, deliveryDate) => {
        if (!canSubmit) {
            handleAddData()
            return;  // exit function if canSubmit is false
        }

        if (formData.contactMethod === 'Telegram') {
            formData.telegram = formData.contactNumber;
        } else if (formData.contactMethod === 'Whatsapp') {
            formData.whatsapp = formData.contactNumber;
        }
        console.log("bbbbbbbbbbbbiiiiiiiiiiiiiiiiiiiiiiillllllllllllllllll", billingInfo)
        const dataToSubmit = {
            email: email,
            name: name,
            telegram: formData.telegram,
            whatsapp: formData.whatsapp,
            deliverDate: deliveryDate,
            orderStatus: "wait for payment",
            paymentWay: "shepa",
            paymentStatus: "pending",
            time: new Date().toISOString(),
            totalPrice: price,
            orders: addedData,
            billing: [
                {
                    name: billingInfo.firstName,
                    lastname: billingInfo.lastName,
                    address: billingInfo.address,
                    city: billingInfo.city,
                    state: billingInfo.state,
                    postcode: billingInfo.postcode,
                    country: billingInfo.country,
                    phone: billingInfo.phoneNumber,
                }
            ]
        };

        
        setFinalData(dataToSubmit)
        try {
            const response = await fetch('https://zyapi.vercel.app/save-order', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(dataToSubmit)
            });
            const data = await response.json();

            if (response.ok) {
                // Redirect to the Invoice component or show success feedback.
                setErrorMessage(currentTranslations.eSubmit);
                setShowInvoice(true); // Show the invoice
                setSubOrder(data)
                return data.order.orderid;
                // Here you can redirect or set some state to display the Invoice component.
            } else {
                // Handle error scenarios
                setErrorMessage(data.message || currentTranslations.sSubmit);
                return null;
            }
        } catch (error) {
            setErrorMessage(currentTranslations.sSubmit);
            return null;
        }
    };


    return (
        <div className={`form-container ${currentLanguage === 'FA' ? 'rtl' : ''}`} >

            {showInvoice ? (
                <>
                    <h1 className='title-h1' >{currentTranslations.titleinvoice}</h1>
                    <Invoice orderDetails={addedData} handleBackToForm={handleBackToForm} handleSubmit={handleSubmitOrder} price={price} formData={formData} email={email}/>
                </>

            ) : (
                <>
                    <h1 className='title-h1' >{currentTranslations.titlepage}</h1>
                    <div className="order-card">

                        <div className="section-one">
                            <p className={`detail-title ${currentLanguage === 'FA' ? 'rtl-title' : ''}`} >{currentTranslations.titleone}</p>
                            {errorMessage && <div className="error-message">{errorMessage}</div>}
                            <select name="cemetery" value={formData.cemetery} onChange={handleInputChange}>
                                <option value="">{currentTranslations.cemeteryPlaceholder}</option>
                                <option value="Behesht-e-Zahra">{currentTranslations.beheshtZahra}</option>
                                <option value="Behesht-e-Sakine">{currentTranslations.beheshtSakine}</option>
                            </select>

                            <input
                                className='name-input'
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                placeholder={currentTranslations.firstName}
                            />

                            <input
                                className='name-input'
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                placeholder={currentTranslations.lastName}
                            />

                            {/* Radio buttons to conditionally display more fields */}
                            <label className='label-input'>
                                <input
                                    className='radio-inputs'
                                    type="radio"
                                    name="detailsSelection"
                                    value="exact"
                                    checked={formData.detailsSelection === 'exact'}
                                    onChange={handleInputChange}
                                />
                                {currentTranslations.knowDetails}
                            </label>

                            <label className='label-input'>
                                <input
                                    className='radio-inputs'
                                    type="radio"
                                    name="detailsSelection"
                                    value="approximate"
                                    checked={formData.detailsSelection === 'approximate'}
                                    onChange={handleInputChange}
                                />
                                {currentTranslations.dontKnowDetails}
                            </label>

                            {/* Conditional fields based on radio button selection */}
                            {formData.detailsSelection === 'exact' && (
                                <div className='inputs-holder'>
                                    <input
                                        className='input-fields'
                                        type="text"
                                        name="piece"
                                        value={formData.piece}
                                        onChange={handleInputChange}
                                        placeholder={currentTranslations.piece}
                                    />
                                    <input
                                        className='input-fields'
                                        type="text"
                                        name="row"
                                        value={formData.row}
                                        onChange={handleInputChange}
                                        placeholder={currentTranslations.row}
                                    />
                                    <input
                                        className='input-fields'
                                        type="text"
                                        name="number"
                                        value={formData.number}
                                        onChange={handleInputChange}
                                        placeholder={currentTranslations.number}
                                    />
                                </div>
                            )}

                            {formData.detailsSelection === 'approximate' && (
                                <div className='inputs-holder'>
                                    <div className="approx-info">{currentTranslations.approxinfo}</div>
                                    <input
                                        className='input-fields'
                                        type="text"
                                        name="approxYear"
                                        value={formData.approxYear}
                                        onChange={handleInputChange}
                                        placeholder={currentTranslations.approximateYear}
                                    />
                                    <input
                                        className='input-fields'
                                        type="text"
                                        name="fathersName"
                                        value={formData.fathersName}
                                        onChange={handleInputChange}
                                        placeholder={currentTranslations.fathersName}
                                    />
                                    <input
                                        className='input-fields'
                                        type="text"
                                        name="approxPiece"
                                        value={formData.approxPiece}
                                        onChange={handleInputChange}
                                        placeholder={currentTranslations.approximatePiece}
                                    />
                                </div>
                            )}



                            <hr className="hr-class" />
                            {/* Checkbox for sound preference */}
                            <label className='label-input'>
                                <input
                                    className='radio-inputs'
                                    type="checkbox"
                                    name="playSound"
                                    checked={formData.playSound}
                                    onChange={(e) => setFormData(prev => ({ ...prev, playSound: e.target.checked }))}
                                />
                                {currentTranslations.playSound}
                            </label>

                            {/* Conditionally show sound option dropdown and description */}
                            {formData.playSound && (
                                <div className='inputs-holder-sound'>
                                    <select
                                        className='input-fields'
                                        name="soundOption"
                                        value={formData.soundOption}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">{currentTranslations.soundOptionsPlaceholder}</option>
                                        <option value="quran">{currentTranslations.soundOptions.quran}</option>
                                        <option value="dua">{currentTranslations.soundOptions.dua}</option>
                                        <option value="customSound">{currentTranslations.soundOptions.customSound}</option>
                                    </select>

                                    {formData.soundOption && (<input

                                        type="text"
                                        name="soundDescription"
                                        value={formData.soundDescription}
                                        onChange={handleInputChange}
                                        placeholder={currentTranslations.soundDescriptionLabels[formData.soundOption] || ""}
                                    />)}
                                </div>
                            )}

                            <button className='form-container-add' onClick={handleAddData}>{currentTranslations.add}</button>

                            <div className='orders-container'>
                                {addedData.map((data, index) => (
                                    <div className='order-holder' key={index}>
                                        <span className='name-holder'>{data.name} {data.lastname}</span>
                                        <span className='close-button' onClick={() => handleRemoveData(index)}>X</span>
                                    </div>
                                ))}
                            </div>

                        </div>


                    
                        <button onClick={handleShowInvoice}>{currentTranslations.submitOrder}</button>

                    </div> </>)}
        </div>
    );
}


export default OrderForm;
