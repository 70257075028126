// Testimonials.js
import React, { useState, useEffect, useContext } from 'react';
import { LanguageContext } from '../../LanguageContext'; // Adjust the path as necessary
import './Testimonial.css';


const testimonialsData = {
    EN: [
        {
            name: 'Sarah',
            image: '../images/testimonial-2.jpg', // The same image URL
            testimonial: "Zendeh Yad has been a godsend for me. Living so far away from home, it was difficult to visit my late father's grave. Thanks to this wonderful service, I know his resting place is well looked after.",
            loc: "New York, USA"
        },
        {
            name: 'Sam',
            image: '../images/testimonial-3.jpg', // The same image URL
            testimonial: 'What a great concept! I was really impressed with how easy it was to book services and receive updates. Everything was seamless.',
            loc: " London, UK"
        },
        {
            name: 'Leily',
            image: '../images/testimonial-1.jpg', // The same image URL
            testimonial: 'The customer service is exceptional. I had a few questions and received immediate and caring responses. Highly recommend Zende Yād for anyone looking to honor their departed loved ones.',
            loc: "Sydney, Australia"
        },

    ],
    FA: [
        {
            name: 'سارا',
            image: '../images/testimonial-2.jpg', // The same image URL
testimonial: 'زنده یاد برای من که نمی‌تونم به وطنم برگردم نعمته، سپاس از شما.',
            loc: "نیویورک، ایالات متحده"
        },
        {
            name: 'سام',
            image: '../images/testimonial-3.jpg', // The same image URL
            testimonial: 'جای چنین امکانی خالی بود، یاد همه‌ی رفتگان زنده باد.',
            loc: "لندن، بریتانیا"
        },
        {
            name: 'لیلی',
            image: '../images/testimonial-1.jpg', // The same image URL
            testimonial: 'بعد از 6 سال که هیچکس نبود سربزنه شما زحمتش رو کشیدید، ممنونم ازتون.',
            loc: "سیدنی، استرالیا"
        },

    ]
};


function Testimonials() {
    const { currentLanguage } = useContext(LanguageContext);
    const [current, setCurrent] = useState(0);

    useEffect(() => {
        const slider = setInterval(() => {
            setCurrent(prev => (prev + 1) % testimonialsData[currentLanguage].length);
        }, 10000); 

        return () => clearInterval(slider); 
    }, [currentLanguage]); // Add dependency to re-initialize the slider if language changes

    const handleNext = () => {
        setCurrent((prev) => (prev + 1) % testimonialsData[currentLanguage].length);
    };

    const handlePrev = () => {
        setCurrent((prev) => (prev - 1 + testimonialsData[currentLanguage].length) % testimonialsData[currentLanguage].length);
    };

    const currentTestimonial = testimonialsData[currentLanguage][current];

    return (
        <div className="testimonial-section">
            <button className="arrow arrow-left" onClick={handlePrev}>&lt;</button> {/* Left arrow */}
            <div className="testimonial-content">
                <img src={currentTestimonial.image} alt={currentTestimonial.name} />
                <div className={`context-holder ${currentLanguage === 'FA' ? 'rtl' : ''}`} style={{ textAlignLast: currentLanguage === 'FA' ? 'right' : 'left' }}>
                    <h3>{currentTestimonial.name}</h3>
                    <p className="location">{currentTestimonial.loc}</p>
                    <p>{currentTestimonial.testimonial}</p>
                </div>
            </div>
            <div className="slider-indicators">
                {testimonialsData[currentLanguage].map((_, idx) => (
                    <button
                        key={idx}
                        className={`indicator ${current === idx ? 'active' : ''}`}
                        onClick={() => setCurrent(idx)}
                    />
                ))}
            </div>
            <button className="arrow arrow-right" onClick={handleNext}>&gt;</button> {/* Right arrow */}
        </div>
    );
    
}

export default Testimonials;

