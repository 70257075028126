import React, { useState, useContext, useEffect } from 'react';
import './Navbar.css';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../LanguageContext';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Navbar() {
    const [showCTA, setShowCTA] = useState(false);
    const [showLogout, setShowLogout] = useState(false);
    const { currentLanguage, setCurrentLanguage } = useContext(LanguageContext);
    const navigate = useNavigate();
    const location = useLocation();
    const isHomePage = location.pathname === '/';  // Change this to your home page path

    const navbarClass = () => {
        if (isHomePage) {
            return showCTA ? 'navbar scrolled' : 'navbar';
        }
        return 'navbar scrolled';  // Always show the 'scrolled' class on other pages
    };

    const handleScroll = () => {
        const offset = window.scrollY;
        setShowCTA(offset > window.innerHeight * 0.5);
    };

    const checkLoginStatus = () => {
        const email = localStorage.getItem('email');
        const passAuthResult = localStorage.getItem('passAuthResult');
        console.log(email ,  passAuthResult)
        return email && passAuthResult;
    };

    const handleLogout = () => {
        localStorage.removeItem('email');
        localStorage.removeItem('name');
        localStorage.removeItem('passAuthResult');
        setShowLogout(false);  // Hide the logout button after logout
        navigate('/');
    };

    const shouldShowLogo = () => {
        if (isHomePage) {
          return showCTA;
        }
        return true;
    };
    useEffect(() => {
      // Read language preference from localStorage
      const storedLanguage = localStorage.getItem('bang');
      console.log("f1iaf1iaf1iaf1ia", storedLanguage)
      if (storedLanguage === 'f1ia') {
          setCurrentLanguage('FA');
      }
  }, [currentLanguage]);

    useEffect(() => {
        setShowLogout(checkLoginStatus());

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={navbarClass()}>
            <a href="/" className="navbar-logo-link">
              { shouldShowLogo() && <img src="/images/dark-logo.png" alt="Company Logo" className="navbar-logo" /> }
            </a> 
            <div className="navbar-right">
              {showCTA &&  <Link to="/submitorder"><button className="hero-cta-nav">{currentLanguage === "EN" ? "Submit Request" : "ثبت درخواست"}</button></Link>}
              {showLogout && <button className="logout" onClick={handleLogout}>{currentLanguage === "EN" ? "Logout" : "خروج"}</button>}
            </div>
        </nav>
    );
}

export default Navbar;
