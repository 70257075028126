import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import './Services.css'; // Import the CSS file here

const translations = {
    EN: {
        service1: "Capture and send a video tour of the environment directly to your email",
        service2: "Choose from supplications or any preferred audio",
        service3: 'Extensive video coverage of many mausoleums'
    },
    FA: {
        service1: "تصویربرداری از محیط و ارسال به ایمیل شما",
        service2: "امکان انتخاب قرائت قرآن، ادعیه یا صوت دلخواه",
        service3: "پوشش آرامگاه های بهشت زهرا و بهشت سکینه"
    }
};

const Services = () => {
    const { currentLanguage } = useContext(LanguageContext);
    const currentTranslations = translations[currentLanguage];

    return (
        <div className="services-container">
            <div className="service">
                <img className='icon-image' src="/images/video.png" alt="Map Icon" width="50" height="50"/>
                <p className="service-description">
                    {currentTranslations.service1}
                </p>
            </div>
            <div className="service">
                <img className='icon-image' src="/images/sound.png" alt="Sound Icon" width="50" height="50"/>
                <p className="service-description">
                    {currentTranslations.service2}
                </p>
            </div>
            <div className="service">
                <img className='icon-image' src="/images/map.png" alt="Video Icon" width="50" height="50"/>
                <p className="service-description">
                    {currentTranslations.service3}
                </p>
            </div>
        </div>
    );
};

export default Services;
