import React, { useContext } from 'react';
import { LanguageContext } from '../../LanguageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
    const { currentLanguage } = useContext(LanguageContext);

    const translations = { 
        EN: {
            about: "About Us",
            contact: "Contact Us",
            privacy: "Privacy",
            refund: "Refund",
            faq: "FAQ",
            description: "A moment of peace by cleaning and lighting a candle on your departed's grave.",
            title: "Zendeh Yad",
            rights: "All rights reserved."
        },
        FA: {
            about: "درباره ما",
            contact: "تماس با ما",
            privacy: "حریم شخصی",
            refund: "بازگشت وجه",
            faq: "سوالات متداول",
            description: "آرامشی هر چند اندک با غبارروبی و افروختن شمع بر مزار رفتگان شما",
            title: "زنده‌یاد",
            rights: "تمام حقوق محفوظ است."
        }
     };
    const currentTranslations = translations[currentLanguage];

    const renderFooterSocket = (
        <div className="footer-socket">
            <p>{currentTranslations.rights}</p>
        </div>
    );
    
    const renderFooterRight = (
        <div className="footer-right">
            <ul className="footer-links">
            <li><Link to="/about">{currentTranslations.about}</Link></li>
      <li><Link to="/contact">{currentTranslations.contact}</Link></li>
      <li><Link to="/privacy">{currentTranslations.privacy}</Link></li>
      <li><Link to="/refund">{currentTranslations.refund}</Link></li>
      <li><Link to="/faq">{currentTranslations.faq}</Link></li>
            </ul>
            <div class='payment-logo-container'>
            <img class='payment-logop' src='/images/perfectmoneysq.png' alt="Perfect Money Logo" />
            <img class='payment-logo' src='/images/mastercard.png' alt="Master Card Logo" />
            <img class='payment-logo' src='/images/visa.png' alt="Visa Logo" />
            
            
            
            </div>
            
        </div>
    );

    const renderFooterLeft = (
        <div className="footer-left">
            <h2>{currentTranslations.title}</h2>
            <p className="footer-description">{currentTranslations.description}</p>
            <div className="footer-social">
                <a href="https://t.me/zendehyadchannel" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTelegram} className="social-icon" />
                </a>
                <a href="https://instagram.com/zendehyaad" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} className="social-icon" />
                </a>
            </div>
        </div>
    );
    console.log(currentLanguage)
    return (
        <div className={`footer-container ${currentLanguage === 'FA' ? 'rtl' : ''}`}>
            <div className="footer">
                {currentLanguage === 'EN' ? (
                    <>
                        {renderFooterLeft}  
                        {renderFooterRight} 
                    </>
                ) : (
                    <>
                    {renderFooterLeft} 
                        {renderFooterRight}
                        
                    </>
                )}
            </div>
            <div className="footer-socket"> {/* This is the socket section */}
                {currentTranslations.rights}
            </div>
        </div>
    );
    
    
}

export default Footer;

