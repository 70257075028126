import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../LanguageContext';
import './Refund.css';

const refundContent = {
  EN: {
    title: "Refund Policy for Zendeh Yad",
    sections: [
      { subtitle: "Last Updated: 2023 Aug", text: "" },
      { subtitle: "Introduction", text: "Thank you for using Zendeh Yad. We understand that sometimes plans change, and this policy outlines our terms for refunds." },
      { subtitle: "Eligibility for Refund", text: "Requests must be made at least 48 hours before the scheduled service date.\nNo refunds for services already rendered." },
      { subtitle: "How to Request a Refund", text: "Refunds can be requested by contacting us via email at info@zendehyad.com or through the contact form on our website." },
      { subtitle: "Processing Time", text: "Once approved, the refund will be processed within 5-7 business days." },
      { subtitle: "Payment Methods", text: "Refunds will be returned via the original payment method." },
      { subtitle: "Cancellation Fees", text: "A cancellation fee of 10% will be deducted from the refund amount." },
      { subtitle: "Contact", text: "If you have any questions about this refund policy, please contact us at info@zendehyad.com." }
    ]
  },
  FA: {
    title: "شرایط بازپرداخت زنده یاد",
    sections: [
      { subtitle: "آخرین به‌روزرسانی: آگوست 2023", text: "" },
      { subtitle: "مقدمه", text: "با تشکر از استفاده شما از زنده یاد. روش بازپرداخت وجه برای درخواست های غیر قابل انجام به شرح زیر است." },
      { subtitle: "شرایط وجود بازپرداخت", text: "درخواست‌ها باید حداقل 48 ساعت قبل از تاریخ خدمات برنامه‌ریزی شده صورت گیرد.\nهیچ بازپرداختی برای خدماتی که قبلاً ارائه شده باز نمی‌گردد." },
      { subtitle: "چگونگی درخواست بازپرداخت", text: "بازپرداخت می‌تواند با تماس با ما از طریق ایمیل به info@zendehyad.com یا از طریق فرم تماس در وب‌سایت ما درخواست شود." },
      { subtitle: "زمان پردازش", text: "پس از تایید، بازپرداخت در طی 5-7 روز کاری انجام خواهد شد." },
      { subtitle: "روش‌های پرداخت", text: "روش بازپرداخت پس از بررسی با شما هماهنگ خواهد شد. (بازپرداخت به روش پرداخت اصلی امکان پذیر نیست)" },
      { subtitle: "هزینه‌های لغو", text: "هزینه لغو به میزان 10% از مبلغ بازپرداخت کسر خواهد شد." },
      { subtitle: "تماس", text: "اگر سوالی در مورد این سیاست بازپرداخت دارید، لطفاً با ما تماس بگیرید در info@zendehyad.com." }
    ]
  }
};

const Refund = () => {
    const { currentLanguage } = useContext(LanguageContext);
    const currentRefundContent = refundContent[currentLanguage];
    
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }, []);

    return (
      <div className={`refund-policy ${currentLanguage === 'FA' ? 'rtl' : ''}`}>
        <div className="card">
          <h1>{currentRefundContent.title}</h1>
          {currentRefundContent.sections.map((section, index) => (
            <div className="section" key={index}>
              <h2>{section.subtitle}</h2>
              <p>{section.text}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

export default Refund;
