import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../LanguageContext';
import './About.css';

const content = {
  EN: {
    title: "About Us",
    sections: [
    {
      subtitle: "",
      text: '"Zendeh Yad" is an online service allowing you to remotely honor your departed loved ones by lighting a candle and cleaning their gravesites. Accessible worldwide, the service plans to expand to cover all cemeteries in the near future.',
    },
      {
        subtitle: "Our Mission",
        text: "At Zende Yād, our mission is to offer a simple, accessible, and heartfelt way to honor the memories of your departed loved ones, no matter where you are in the world. We provide grave cleaning and candle lighting services at select cemeteries, letting you pay your respects even when you can't be there in person."
      },
      {
        subtitle: "Who We Are",
        text: "We are a dedicated team passionate about providing meaningful services that help people connect with their departed loved ones. Founded in 2023, we've been continually striving to improve and expand our offerings, aiming to make them available across more locations soon."
      },
      {
        subtitle: "Our Unique Approach",
        text: "What sets us apart is our commitment to socio-economic responsibility. We exclusively employ individuals who face employment challenges and can't undertake every type of job due to certain limitations. This not only allows us to provide a needed service but also gives work opportunities to those who might otherwise struggle to find employment."
      },
      {
        subtitle: "How It Works",
        text: "You can book our services with just a few clicks on our website. Once the services are performed, we send photos and videos to your chosen contact method, be it email, WhatsApp, or Telegram, so you can have peace of mind knowing your loved one's resting place is well cared for."
      },
      {
        subtitle: "FAQ",
        text: "For more information, please check out our Frequently Asked Questions. We appreciate your interest in Zende Yad and look forward to serving you."
      }
    ]
  },
  FA: {
    title: "درباره ما",
    sections: [
      {
        subtitle: "",
        text: 'زنده یاد یک سرویس آنلاین است که به شما اجازه می‌دهد از راه دور برای احترام به عزیزانی که دیگر نیستند، شمع روشن کنید و مزارشان را غبارروبی کنید. ثبت درخواست این سرویس از سراسر جهان قابل دسترسی است و در آینده نزدیک پوشش سرویس به تمام آرامگاه‌های ایران توسعه خواهد یافت.',
      },

    ]
  }
};

const About = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const currentContent = content[currentLanguage];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);
  return (
    <div className={`about-us ${currentLanguage === 'FA' ? 'rtl' : ''}`}>
      <div className="card">
        <h1>{currentContent.title}</h1>
        {currentContent.sections.map((section, index) => (
          <div key={index}>
            <h2>{section.subtitle}</h2>
            <p>{section.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
