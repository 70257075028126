import React, { useContext } from 'react';
import './Hero.css';
import { LanguageContext } from '../../LanguageContext';
import { Link } from 'react-router-dom';


function Hero() {
    const { currentLanguage } = useContext(LanguageContext);
    const headerImage = "/images/header.jpg";

    const translations = {
        EN: {
            title: "Zandeh Yad",
            slogan: "A candle for the departed",
            cta: "Submit Request",
        },
        FA: {
            title: "زنده‌یاد",
            slogan: "شمعی بر مزار رفتگان",
            cta: "ثبت درخواست",
        }
    };

    const currentTranslations = translations[currentLanguage];

    const fontClassName = currentLanguage === 'FA' ? 'iran-sans' : 'math-font'; // "math-font" would be the class name for the "Math" font

    return (
        <div className={`hero-container ${fontClassName}`} style={{ backgroundImage: `url(${headerImage})` }}>
            <div className="hero-overlay">
                <div className="hero-content">
                    <h1 className="hero-title">{currentTranslations.title}</h1>
                    <p className="hero-slogan">{currentTranslations.slogan}</p>
                    <Link to="/submitorder">
                        <button className="hero-cta">{currentTranslations.cta}</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Hero;
