    import React, { useState, useContext } from 'react';
    import PropTypes from 'prop-types';
    import { LanguageContext } from '../../LanguageContext';
    import './PasswordEntry.css';

    function PasswordEntry({ email, name, isNewUser, proceedToOrderForm }) {
        const { currentLanguage } = useContext(LanguageContext);
        const [password, setPassword] = useState("");
        const [error, setError] = useState(null);
        const [isLoading, setIsLoading] = useState(false);

        const translations = {
            EN: {
                placeholder: "Password",
                nextButton: "Next",
                error: "Password is incorrect",
                label: "Enter your password",
                labelNewUser: "Enter your password:",
                passInfo: "The password has been sent to your email upon your first visit."
            },
            FA: {
                placeholder: "رمز عبور",
                nextButton: "بعدی",
                error: "رمز عبور نادرست است",
                label: "رمز عبور خود را وارد کنید:",
                labelNewUser: "رمز عبور خودرا وارد کنید:",
                passInfo: "رمز عبور در اولین مراجعه به ایمیل شما ارسال شده است."
            }
        };

        const currentTexts = translations[currentLanguage];

        const handlePasswordChange = e => {
            setPassword(e.target.value);
        };

        const handleSubmit = async () => {
            const response = await checkPasswordWithAPI(email, password);
            if (response.isValidPassword) {
                localStorage.setItem('email', email);
                localStorage.setItem('passAuthResult', 'true'); 
                localStorage.setItem('name', name);
                
                proceedToOrderForm();

                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    });

            } else {
                setPassword("");
                setError(currentTexts.error);
            }
        };

        const checkPasswordWithAPI = async (email, password) => {
            setIsLoading(true);
            try {
                const response = await fetch('https://zyapi.vercel.app/check-password', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email, password })
                });
        
                const data = await response.json();
                localStorage.setItem('billing', JSON.stringify(data.user.billing));

                console.log(data);
                if (data.status) {
                    return { isValidPassword: true };
                } else {
                    return { isValidPassword: false };
                }
            } catch (error) {
                console.error("Error in checking password:", error);
                return { isValidPassword: false };
            } finally { // This block ensures setIsLoading(false) will always run
                setIsLoading(false);
            }
        };
        
        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                handleSubmit();
            }
        };
        

        return (
            <div className={`password-entry-container ${currentLanguage === 'FA' ? 'rtl' : ''}`}>
                {isLoading && (
                    <div className="loader-container">
                        <div className="loader"></div> 
                    </div>
                )}
                <form
                    className="email-input-section"
                    onSubmit={(e) => {
                        e.preventDefault(); // This prevents the form from reloading the page
                        handleSubmit();
                    }}
                >
                    <label className="password-label">
                        {isNewUser ? currentTexts.labelNewUser : currentTexts.label}
                    </label>
                    {!isNewUser ? <div className="pass-info">{currentTexts.passInfo}</div> : <div></div>}
                    <input
                        className="email-input"
                        type="password"
                        value={password}
                        placeholder={currentTexts.placeholder}
                        onChange={handlePasswordChange}
                        onKeyDown={handleKeyDown} // Here we listen for keydown event
                        required
                    />
                    {error && <span className="error-text">{currentTexts.error}</span>}
                    <button className="next-button" type="submit">
                        {currentTexts.nextButton}
                    </button>
                </form>
            </div>
        );
    }

    PasswordEntry.propTypes = {
        email: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        isNewUser: PropTypes.bool.isRequired,
        proceedToOrderForm: PropTypes.func.isRequired
    };


    export default PasswordEntry;
