import React, { useState, useContext, useEffect } from 'react';
import { LanguageContext } from '../../LanguageContext';

function Invoice({ orderDetails, handleBackToForm, handleSubmit, formData, price, name, email}) {
    const { currentLanguage } = useContext(LanguageContext);
    const [errorMessage, setErrorMessage] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [billingInfo, setBillingInfo] = useState({
        firstName: '',
        lastName: '',
        country: '',
        city: '',
        state: '',
        address: '',
        postcode: '',
        phoneNumber: '',
        countryCode: '+44',  // Initialize with a default value
    localNumber: '',
    });

      
      useEffect(() => {
        billingInfo.phoneNumber = billingInfo.countryCode + billingInfo.localNumber
        console.log("billingInfo has been updated:", billingInfo);
        console.log("billingInfo has been updated:", billingInfo.phoneNumber);

    }, [billingInfo]);

    const validateBillingInfo = () => {
        // Check if any of the billing fields are empty
        for (const [key, value] of Object.entries(billingInfo)) {
            if (!value) {
                setErrorMessage(`Please fill in the ${key.replace(/([A-Z])/g, ' $1').toLowerCase()} field`);
                return false;
            }
        }
        // Clear error message if form is valid
        setErrorMessage(null);
        return true;
    }

    const storedEmail = localStorage.getItem('email');

    const handlePayment = async (orderId, orderDetails, price) => {
        setIsLoading(true);
        try {
            if (!orderId) {
                console.error('Order ID not generated');
                return;
              }
          const response = await fetch('https://merchant.shepa.com/api/v1/token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              api: '6418d5d3-ff84-4659-851f-cfa914d3a43f',
              amount: price,
              callback: `https://zyapi.vercel.app/payment-callback?orderid=${orderId}`,
              mobile: billingInfo.phoneNumber,
              email: email,
              order: {
                total: price,
                billing: {
                  first_name: billingInfo.firstName,
                  last_name: billingInfo.lastName,
                  address_1: billingInfo.address,
                  city: billingInfo.city,
                  state: billingInfo.state,
                  postcode: billingInfo.postcode,
                  country: billingInfo.country,
                  email: email,
                  phone: billingInfo.phoneNumber,
                },
                products: [
                  {
                    id: 1,
                    name: 'Grave Cleaning Service',
                    price: 10,
                    qty: price / 10,
                  },
                ],
              },
            }),
          });
      
          const data = await response.json();
          console.log("datadatadatadata", data)
          console.log("StripeStripe", data.result.national_urls.Stripe)
          console.log("GPayGPayGPay", data.result.national_urls.GPayment)
          console.log("urlurlurl", data.result.url)


          if (data.success) {
            const { token } = data.result;
            const url = data.result.url
            if (selectedPaymentMethod === 'Stripe') {
                window.location.href = `${url}/Stripe?token=${token}`;
            } else if (selectedPaymentMethod === 'GPay') {
                window.location.href = `${url}/Gpayment?token=${token}`;
            } 
            else if (selectedPaymentMethod === 'PerfectMoney') {
                window.location.href = `${url}?token=${token}`;
            }
            
            
        }
        } catch (error) {
            setIsLoading(false);
          console.error('Error getting token:', error);
        }
      };
      
    
      const handleInvoiceSubmit = async () => {
        setIsLoading(true);
        if (!selectedPaymentMethod) {
            setErrorMessage('Please select a payment method.');
            setIsLoading(false);
            return;
        }
        if (validateBillingInfo() && validatePhoneNumber(billingInfo.localNumber)) {
            const orderId = await handleSubmit(billingInfo, deliveryDate);
            handlePayment(orderId, orderDetails, price);
        } else {
            setIsLoading(false);
            setErrorMessage(currentTranslations.phoneError);
        }


      };

      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setBillingInfo({
            ...billingInfo,
            [name]: value,
        });
    };

    const validatePhoneNumber = (localNumber) => {
        const re = /^[0-9\b]+$/; // allows only numbers and backspace
        return re.test(String(localNumber));
    };

    const determineDeliveryDate = () => {
        const today = new Date();
        const nextThursday = new Date(today);

        // If today is before or on Wednesday (0=Sunday, 1=Monday, ..., 6=Saturday)
        if (today.getDay() <= 3) {
            nextThursday.setDate(today.getDate() + (4 - today.getDay())); // This Thursday
        } else {
            nextThursday.setDate(today.getDate() + (11 - today.getDay())); // Next Thursday
        }

        // Format date as 'Thursday 21 July 2023'
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return nextThursday.toLocaleDateString(currentLanguage === 'EN' ? 'en-US' : 'fa-IR', options);
    };

    const deliveryDate = determineDeliveryDate();
    const translations = {
        EN: {
            invoice: "Invoice",
            name: "Name:",
            cemetery: "Cemetery:",
            piece: "Piece:",
            row: "Row:",
            number: "Number:",
            approxYear: "Approximate Year:",
            fatherName: "Father's Name:",
            approxPiece: "Approximate Piece:",
            soundOption: "Sound Option:",
            soundDescription: "Sound Description:",
            payment: "Payment",
            choosePaymentMethod: "Choose your payment method:",
            payCreditCard: "Pay with Credit Card",
            payPayPal: "Pay with PayPal",
            price: "Price",
            email: "Email",
            deliveryDate: "Order Delivery Date",
            pay: "Proceed to Payment",
            back: "Back",
            orderDetails: "Order Details",
            userDetails: "Delivery and Price Details",
            Billing: "Billing Information",
            firstName: "First Name",
    lastName: "Last Name",
    country: "Country",
    city: "City",
    state: "State",
    address: "Address",
    postcode: "Postcode",
    countryCode: "Country Code",
    phoneNumber: "Phone Number",
    phoneError: "Invalid phone number format",
    payStripe: "Stripe",
    payGPay: "Gpay"
        },
        FA: {
            invoice: "",
            name: "نام:",
            cemetery: "آرامگاه:",
            piece: "قطعه:",
            row: "ردیف:",
            number: "شماره:",
            approxYear: "سال تقریبی:",
            fatherName: "نام پدر:",
            approxPiece: "قطعه تقریبی:",
            soundOption: "گزینه‌ی صوتی:",
            soundDescription: "توضیحات صوت:",
            payment: "پرداخت",
            choosePaymentMethod: "انتخاب روش پرداخت",
            payCreditCard: "پرداخت با کارت اعتباری",
            payPayPal: "پرداخت با پی‌پال",
            price: "هزینه",
            email: "ایمیل",
            deliveryDate: "زمان انجام درخواست",
            pay: "پرداخت",
            back: "< بازگشت",
            orderDetails: "مشخصات زنده‌یاد",
            userDetails: "جزئیات درخواست",
            Billing: "مشخصات پرداخت کننده",
            firstName: "نام",
    lastName: "نام خانوادگی",
    country: "کشور",
    city: "شهر",
    state: "استان",
    address: "آدرس",
    postcode: "کد پستی",
    phoneNumber: "شماره همراه",
    countryCode: "پیش شمازه",
    phoneError: "لطفا شماره همراه خود را مجدد بررسی کنید. ",
    payStripe: "Stripe",
    payGPay: "Gpay"
        }
    };

    const currentTranslations = translations[currentLanguage];

    return (
        <div className="invoice-container">
            <h2 className="invoice-title">{currentTranslations.invoice}</h2>



            <div className="order-details">
                <p className={`${currentLanguage === 'EN' ? 'order-instruction-en' : 'order-instruction'}`} >{currentTranslations.orderDetails}</p>
                {orderDetails.map((order, index) => (
                    <div className="order-item" key={index}>
                        <strong>{currentTranslations.name}</strong> {order.name} {order.lastname} <br />
                        <strong>{currentTranslations.cemetery}</strong> {order.cemetery} <br />
                        {order.detailsSelection === 'exact' && (
                            <>
                                <strong>{currentTranslations.piece}</strong> {order.piece} <br />
                                <strong>{currentTranslations.row}</strong> {order.row} <br />
                                <strong>{currentTranslations.number}</strong> {order.number} <br />
                            </>
                        )}
                        {order.detailsSelection === 'approximate' && (
                            <>
                                {order.approxYear && (
                                    <>
                                        <strong>{currentTranslations.approxYear}</strong> {order.approxYear} <br />
                                    </>
                                )}
                                {order.fatherName && (
                                    <>
                                        <strong>{currentTranslations.fatherName}</strong> {order.fatherName} <br />
                                    </>
                                )}
                                {order.approxPiece && (
                                    <>
                                        <strong>{currentTranslations.approxPiece}</strong> {order.approxPiece} <br />
                                    </>
                                )}
                            </>
                        )}
                        {order.playSound && (
                            <>
                                <strong>{currentTranslations.soundOption}</strong> {order.soundOption} <br />
                                <strong>{currentTranslations.soundDescription}</strong> {order.soundDescription} <br />
                            </>
                        )}

                    </div>
                ))}
            </div>
            <div className='order-details-holder'>
                <p className={`${currentLanguage === 'EN' ? 'payment-instruction-en' : 'payment-instruction'}`}>{currentTranslations.userDetails}</p>
                {storedEmail && <p className="email-line"><strong>{currentTranslations.email}:</strong> {storedEmail}</p>}

                <div className="delivery-time">
                    <strong>{currentTranslations.deliveryDate}: </strong>{deliveryDate}
                </div>
                <div className="payment-info">
                    <strong>{currentTranslations.price}: $</strong>{price}
                </div>
            </div>

            <div className="payment-container">
                <p className={`${currentLanguage === 'EN' ? 'payment-instruction-en' : 'payment-instruction'}`}>{currentTranslations.choosePaymentMethod}</p>



                <div className="payment-buttons">
                <div className="payment-cards">
    {/* <div
        className={`payment-card ${selectedPaymentMethod === 'Stripe' ? 'selected' : ''}`}
        onClick={() => setSelectedPaymentMethod('Stripe')}
    ><img src='/images/stripeLogo.png' alt="Stripe Logo" />

    </div> */}
    <div
        className={`payment-card ${selectedPaymentMethod === 'GPay' ? 'selected' : ''}`}
        onClick={() => setSelectedPaymentMethod('GPay')}
    ><img src='/images/mastercard.png' alt="mMstercard Logo" class='pay-logo'/>
    <img src='/images/visa.png' alt="Visa Logo" class='pay-logo' />
    </div>
    <div
        className={`payment-card ${selectedPaymentMethod === 'PerfectMoney' ? 'selected' : ''}`}
        onClick={() => setSelectedPaymentMethod('PerfectMoney')}
    ><img src='/images/perfectmoney.png' alt="PerfectMoney Logo" />

    </div>
</div>


                </div>
            </div>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {/* Billing Information Form */}

<div className="billing-info-form">
    <p className={`${currentLanguage === 'EN' ? 'billing-instruction-en' : 'billing-instruction'}`}>{currentTranslations.Billing}</p>
    <form>
    <div className="row phone-row">
    <select name="countryCode" className="half-width country-code" value={billingInfo.countryCode} onChange={handleInputChange}>
        <option value="" >{currentTranslations.countryCode}</option> {/* Default value */}
        <option value="0054">+54 (Argentina)</option>
        <option value="0061">+61 (Australia)</option>
        <option value="0043">+43 (Austria)</option>
        <option value="0032">+32 (Belgium)</option>
        <option value="0086">+86 (China)</option>
        <option value="00357">+357 (Cyprus)</option>
        <option value="0045">+45 (Denmark)</option>
        <option value="0020">+20 (Egypt)</option>
        <option value="00358">+358 (Finland)</option>
        <option value="0033">+33 (France)</option>
        <option value="00995">+995 (Georgia)</option>
        <option value="0049">+49 (Germany)</option>
        <option value="0030">+30 (Greece)</option>
        <option value="0091">+91 (India)</option>
        <option value="0062">+62 (Indonesia)</option>
        <option value="0081">+81 (Japan)</option>
        <option value="00965">+965 (Kuwait)</option>
        <option value="0060">+60 (Malaysia)</option>
        <option value="00976">+976 (Mongolia)</option>
        <option value="0031">+31 (Netherlands)</option>
        <option value="0064">+64 (New Zealand)</option>
        <option value="0047">+47 (Norway)</option>
        <option value="00968">+968 (Oman)</option>
        <option value="0063">+63 (Philippines)</option>
        <option value="0048">+48 (Poland)</option>
        <option value="00351">+351 (Portugal)</option>
        <option value="00974">+974 (Qatar)</option>
        <option value="0027">+27 (South Africa)</option>
        <option value="0082">+82 (South Korea)</option>
        <option value="0034">+34 (Spain)</option>
        <option value="0046">+46 (Sweden)</option>
        <option value="0041">+41 (Switzerland)</option>
        <option value="00886">+886 (Taiwan)</option>
        <option value="0066">+66 (Thailand)</option>
        <option value="00993">+993 (Turkmenistan)</option>
        <option value="0090">+90 (Turkey)</option>
        <option value="00380">+380 (Ukraine)</option>
        <option value="00971">+971 (United Arab Emirates)</option>
        <option value="0044">+44 (United Kingdom)</option>
        <option value="001">+1 (United States)</option>
    </select>
    <input
    className="half-width"
        type="text"
        name="localNumber"
        placeholder={currentTranslations.phoneNumber}
        onChange={handleInputChange}
        value={billingInfo.localNumber}
    />
</div>


<div className="row">
            <input className="half-width" type="text" name="firstName" placeholder={currentTranslations.firstName} onChange={handleInputChange}  />
            <input className="half-width" type="text" name="lastName" placeholder={currentTranslations.lastName} onChange={handleInputChange}  />
        </div>

        <div className="row">
        <select className="half-width" name="country" onChange={handleInputChange}>
  <option value="" >{currentTranslations.country}</option>
  <option value="AR">Argentina</option>
  <option value="AU">Australia</option>
  <option value="AT">Austria</option>
  <option value="BE">Belgium</option>
  <option value="CN">China</option>
  <option value="CY">Cyprus</option>
  <option value="DK">Denmark</option>
  <option value="EG">Egypt</option>
  <option value="FI">Finland</option>
  <option value="FR">France</option>
  <option value="GE">Georgia</option>
  <option value="DE">Germany</option>
  <option value="GR">Greece</option>
  <option value="IN">India</option>
  <option value="ID">Indonesia</option>
  <option value="JP">Japan</option>
  <option value="KW">Kuwait</option>
  <option value="MY">Malaysia</option>
  <option value="MN">Mongolia</option>
  <option value="NL">Netherlands</option>
  <option value="NZ">New Zealand</option>
  <option value="NO">Norway</option>
  <option value="OM">Oman</option>
  <option value="PH">Philippines</option>
  <option value="PL">Poland</option>
  <option value="PT">Portugal</option>
  <option value="QA">Qatar</option>
  <option value="ZA">South Africa</option>
  <option value="KR">South Korea</option>
  <option value="ES">Spain</option>
  <option value="SE">Sweden</option>
  <option value="CH">Switzerland</option>
  <option value="TW">Taiwan</option>
  <option value="TH">Thailand</option>
  <option value="TM">Turkmenistan</option>
  <option value="TR">Turkey</option>
  <option value="UA">Ukraine</option>
  <option value="AE">United Arab Emirates</option>
  <option value="GB">United Kingdom</option>
  <option value="US">United States</option>
</select>

            <input className="half-width" type="text" name="state" placeholder={currentTranslations.state} onChange={handleInputChange}  />
        </div>

        <div className="row">
            <input className="half-width" type="text" name="city" placeholder={currentTranslations.city} onChange={handleInputChange} />
            <input className="half-width" type="text" name="postcode" placeholder={currentTranslations.postcode} onChange={handleInputChange}  />
        </div>

        <div className="row">
            <input className="full-width" type="text" name="address" placeholder={currentTranslations.address} onChange={handleInputChange}  />
        </div>
        
    </form>
</div>


            <div className="invoice-actions">
                <button className="back-button" onClick={handleBackToForm}>{currentTranslations.back}</button>
                <button className="submit-button" onClick={handleInvoiceSubmit} disabled={isLoading}>
        {isLoading ? (
            <span className="loader-butt"></span> // Add a class for the loader's styling
        ) : (
            currentTranslations.pay
        )}
    </button>
            </div>
        </div>
    );

}


export default Invoice;
