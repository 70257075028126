import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LanguageContext } from '../../LanguageContext';

const OrderStatus = () => {
  const location = useLocation();

  const [order, setOrder] = useState(null);
  const { currentLanguage } = useContext(LanguageContext);
  
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const status = urlParams.get('success');
    const message = urlParams.get('message');
    const orderData = urlParams.get('order');

    if (status === 'true') {
      // Assume the order is URL-encoded and needs to be decoded
      const decodedOrder = JSON.parse(decodeURIComponent(orderData));
      console.log(decodedOrder)
      setOrder(decodedOrder);
    } else {
      // Handle specific error messages
      if (message === 'VerificationFailed') {
        console.error("Verification failed:", message);
        setOrder({ error: translations[currentLanguage].verificationFailed });
    } else if (message === 'PaymentFailed') {
        console.error("Payment failed:", message);
        setOrder({ error: translations[currentLanguage].paymentFailed });
    } else {
        console.error("Payment was not successful:", message);
        setOrder(null);  // This will set a generic error message from the backend if available
    }
    }
  }, [location.search, currentLanguage]);

  console.log("oooooooooooooooooooo",order)
  function localizeDate(dateString, currentLanguage) {
    // Parse the input date string into a Date object
    const parts = dateString.replace(" ", "").split(",");
    const [dayName, monthName, day, year] = parts;
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthIndex = months.indexOf(monthName);
    
    const parsedDate = new Date(year, monthIndex, day);
    
    // Format the date based on the current language
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return parsedDate.toLocaleDateString(currentLanguage === 'EN' ? 'en-US' : 'fa-IR', options);
  }
  
  


  const translations = {
    EN: {
        invoice: "Request Result",
        name: "Name:",
        cemetery: "Cemetery:",
        piece: "Piece:",
        row: "Row:",
        number: "Number:",
        approxYear: "Approximate Year:",
        fatherName: "Father's Name:",
        approxPiece: "Approximate Piece:",
        soundOption: "Sound Option:",
        soundDescription: "Sound Description:",
        payment: "Payment",
        choosePaymentMethod: "Choose your payment method:",
        payCreditCard: "Pay with Credit Card",
        payPayPal: "Pay with PayPal",
        price: "Price",
        email: "Email",
        deliveryDate: "Order Delivery Date",
        pay: "Proceed to Payment",
        back: "Back",
        orderDetails: "Order Details",
        userDetails: "Delivery and Price Details",
        successMessage: "Your request has been successfully registered; the video and pictures will be sent to your email after the request is processed.",
        verificationFailed: "Verification failed. Please try again or contact support.",
        paymentFailed: "Payment failed. Please check your payment details and try again.",
        nOrder: "Order details are missing or payment was not successful."
    },
    FA: {
        invoice: "نتیجه درخواست",
        name: "نام:",
        cemetery: "آرامگاه:",
        piece: "قطعه:",
        row: "ردیف:",
        number: "شماره:",
        approxYear: "سال تقریبی:",
        fatherName: "نام پدر:",
        approxPiece: "قطعه تقریبی:",
        soundOption: "گزینه‌ی صوتی:",
        soundDescription: "توضیحات صوت:",
        payment: "پرداخت",
        choosePaymentMethod: "انتخاب روش پرداخت",
        payCreditCard: "پرداخت با کارت اعتباری",
        payPayPal: "پرداخت با پی‌پال",
        price: "هزینه",
        email: "ایمیل",
        deliveryDate: "زمان انجام درخواست",
        pay: "پرداخت",
        back: "< بازگشت",
        orderDetails: "مشخصات زنده‌یاد",
        userDetails: "جزئیات درخواست",
        successMessage: "درخواست شما با موفقیت ثبت شد، فیلم و تصاویر بعد از انجام به ایمیل شما ارسال خواهد شد.",
        verificationFailed: "تایید پرداخت ناموفق بود. لطفاً دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.",
        paymentFailed: "پرداخت ناموفق بود. لطفاً جزئیات پرداخت خود را بررسی کنید و دوباره تلاش کنید.",
        nOrder: "جزئیات سفارش موجود نیست یا پرداخت موفق نبود."
    }
};

const currentTranslations = translations[currentLanguage];

  if (order && order.error) {
    return (
<div className={`form-container ${currentLanguage === 'FA' ? 'rtl' : ''}`} >
      <div className="result-container">
      <div className="error-message">
      {order.error}
        </div>
      </div>
    </div>
    )
}

if (!order) {
  return (
    <div className={`form-container ${currentLanguage === 'FA' ? 'rtl' : ''}`} >
          <div className="result-container">
          <div className="error-message">
          {currentTranslations.nOrder}
            </div>
          </div>
        </div>
        )
}


  return (
    <div className={`form-container ${currentLanguage === 'FA' ? 'rtl' : ''}`} >
      <h2 className="invoice-title">{currentTranslations.invoice}</h2>

    <div className="result-container">

            <div className="alert-success">
            {currentTranslations.successMessage}
            </div>


    <div className="order-details">
        <p className={`${currentLanguage === 'EN' ? 'order-instruction-en' : 'order-instruction'}`} >{currentTranslations.orderDetails}</p>
        {order.orders && order.orders.map((order, index) => (
            <div className="order-item" key={index}>
                <strong>{currentTranslations.name}</strong> {order.name} {order.lastname} <br />
                <strong>{currentTranslations.cemetery}</strong> {order.cemetery} <br />
                {order.detailsSelection === 'exact' && (
                    <>
                        <strong>{currentTranslations.piece}</strong> {order.piece} <br />
                        <strong>{currentTranslations.row}</strong> {order.row} <br />
                        <strong>{currentTranslations.number}</strong> {order.number} <br />
                    </>
                )}
                {order.detailsSelection === 'approximate' && (
                    <>
                        {order.approxYear && (
                            <>
                                <strong>{currentTranslations.approxYear}</strong> {order.approxYear} <br />
                            </>
                        )}
                        {order.fatherName && (
                            <>
                                <strong>{currentTranslations.fatherName}</strong> {order.fatherName} <br />
                            </>
                        )}
                        {order.approxPiece && (
                            <>
                                <strong>{currentTranslations.approxPiece}</strong> {order.approxPiece} <br />
                            </>
                        )}
                    </>
                )}
                {order.playSound && (
                    <>
                        <strong>{currentTranslations.soundOption}</strong> {order.soundOption} <br />
                        <strong>{currentTranslations.soundDescription}</strong> {order.soundDescription} <br />
                    </>
                )}

            </div>
        ))}
    </div>
    <div className='order-details-holder'>
        <p className={`${currentLanguage === 'EN' ? 'payment-instruction-en' : 'payment-instruction'}`}>{currentTranslations.userDetails}</p>
        {order.email && <p className="email-line"><strong>{currentTranslations.email}:</strong> {order.email}</p>}

        <div className="delivery-time">
            <strong>{currentTranslations.deliveryDate}: </strong>{order.deliverDate}
        </div>

    </div>


    </div>
    </div>
  );
};

export default OrderStatus;
