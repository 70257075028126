import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    // First, attempt to get language preference from localStorage.
    const initialLang = localStorage.getItem('bang') ? "FA" : 'EN';

    const [currentLanguage, setCurrentLanguage] = useState(initialLang);

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    // Update query parameter name from 'lang' to 'bang'
    const bangParam = query.get('bang');

    useEffect(() => {
        // If the bang parameter exists in the URL, change the current language
        if (bangParam === 'f1ia' || initialLang === "FA") {
            setCurrentLanguage('FA');
            localStorage.setItem('bang', 'f1ia');
        } else if (!bangParam) { // When the 'bang' parameter has no value, set to 'EN'
            setCurrentLanguage('EN');
        }
        // If bangParam has any other value, it will default to 'EN' due to initialLang
    }, [bangParam]);

    useEffect(() => {
        // Update the document title based on language.
        const titles = {
            'EN': 'Zendeh Yad | A candle for the departed',
            'FA': 'زنده‌یاد | شمعی بر مزار رفتگان'
        };

        document.title = titles[currentLanguage];
    }, [currentLanguage]);

    useEffect(() => {
        // Save the current language to localStorage whenever it changes.
        localStorage.setItem('userLang', currentLanguage);
    }, [currentLanguage]);

    return (
        <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
