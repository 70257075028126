import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '../../LanguageContext';
import './FAQ.css';

const faqContent = {
    EN: {
      title: "Frequently Asked Questions",
        questions: [

        {
          question: "How can I place an order?",
          answer: "You can place your order through the form available on our website. Soon, we will also offer text and phone support for bookings."
        },
        {
          question: "When will my order be executed?",
          answer: "If you place your order by Wednesday, it will be carried out and sent to you by the end of the day on the following Friday."
        },
        {
          question: "How will my order be delivered?",
          answer: "By default, photos and videos will be sent to your email. However, you can also choose to receive them via WhatsApp or Telegram."
        },
        {
          question: "How can I make a payment?",
          answer: "Currently, we only accept payments in USD. Soon, we will add options for payments in Euro, Dirham, and digital currencies."
        },
        {
          question: "From which countries can I make a payment?",
          answer: "Payments are not possible from restricted countries such as Afghanistan, Pakistan, Russia, and Iran due to financial sanctions."
        },
        {
          question: "Are there any limitations on placing orders?",
          answer: "Yes, celebrities and individuals who appear in internet search results are not eligible to use this service."
        }
      ]
    },
    FA: {
      title: "پرسش‌ها و پاسخ‌ها",
        questions: [
          {
            question: "چطور می‌توانم درخواستم را ثبت کنم؟",
            answer: "از طریق فرم موجود در وبسایت می‌توانید درخواست خود را ثبت کنید. مراحل ثبت و توضیحات کامل در مورد جزئیات ثبت سفارش در اینستاگرام و کانال تلگرام زنده‌یاد منتشر شده است."
          },
        {
          question: "آیا فقط در بهشت زهرا و بهشت سکینه می‌توانم درخواست ثبت کنم؟",
          answer: "بلی، در حال حاضر تنها این دو آرامگاه تحت پشتیانی ما هستند. ولی به زودی توسعه خدمات به سایر آرامگاه‌ها انجام خواهد شد."
        },
        
        {
          question: "درخواست من کی اجرا می‌شود؟",
          answer: "اگر درخواست شما تا قبل از روز چهارشنبه ثبت شود، تا پایان روز پنجشنبه همان هفته اجرا و ارسال خواهد شد، سفارشات ثبت شده در روزهای چهارشنبه، پنجشنبه‌ی هفته‌ی بعد انجام خواهد شد."
        },
        {
          question: "درخواست من چگونه برای من ارسال می‌شود؟",
          answer: "به طور پیش‌فرض، تصاویر و ویدئو به ایمیل شما ارسال می‌شود."
        },
        {
          question: "چطور می‌توانم پرداخت کنم؟",
          answer: "برای راحتی ثبت درخواست از نقاط مختلف دنیا زنده‌یاد از پرداخت با استرایپ (مستر کارت، ویزا کارت، دیسکاور و امریکن اکسپرس)، گوگل‌پی و پرفکت مانی پشتیبانی می‌کند."
        },
        {
          question: "از کدام کشورها می‌توانم پرداخت کنم؟",
          answer: "پرداخت از کشورهای محدودی مانند افغانستان، پاکستان، روسیه و ایران به دلیل تحریم‌های مالی امکان‌پذیر نیست."
        },
        {
          question: "آیا در ثبت درخواست محدودیت‌هایی وجود دارد؟",
          answer: "بله، متاسفانه سرویس دهی برای رفتگانی که صاحب شهرت بوده‌اند و یا در نتایج جستجوی اینترنتی اطلاعاتی در موردشان وجود دارد امکان‌پذیر نیست."
        }
      ]
    }
  };
  

const FAQ = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const currentFAQ = faqContent[currentLanguage];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className={`faq ${currentLanguage === 'FA' ? 'rtl' : ''}`}>
      <div className="card">
        <h1>{currentFAQ.title}</h1>
        {currentFAQ.questions.map((item, index) => (
          <div key={index}>
            <strong>{item.question}</strong>
            <p>{item.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
